.rank-title{
    font-size: 21px;
    font-weight: 600;
    @media screen and (max-width: 800px) {
        font-size: 18px;
    }
}

.ranking-background{
    box-shadow: 0px 4px 8px rgba(0,0,0,0.05);
    -webkit-filter: grayscale(1);
    opacity: .5; 
}

.rank-status{
    background-color: black;
    position: absolute;
    right: 30px;
    top: 80px;
    border-radius: 5px;
    padding: 10px 20px;
    @media screen and (max-width: 800px) {
        top: 15px;
        right: 10px;
        padding: 5px 10px;
    }
}

.current-status{
    @media screen and (max-width: 800px) {
        display: none;
    }
}

.partner-checked{
    width: 24px;
    height: 24px;
    background-color: #4830C1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px 0 0;
}

.partner-unchecked{
    width: 24px;
    height: 24px;
    border: 2px solid #4830C1;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px 0 0;
}