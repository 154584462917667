.activations_tbl {
  width: 100%; /* Optional: Set table width */
  border-collapse: collapse; /* Ensure borders are collapsed */
  border: 2px solid black; /* Set border for the entire table */
}

.activations_tbl th,
.activations_tbl td {
  border: 1px solid black; /* Set border for table cells */
 
}


.activations_tbh {
 padding: 5px; /* Set border for table cells */
}