.teams-user-line {
  border-left: 1px dashed #fff;
  height: 20px;
}

.team-border {
  border-top: 1.5px solid #fff;
  border-left: 1.5px solid #fff;
  border-right: 1.5px solid #fff;
  height: 10px;
  border-radius: 5px 5px 0 0;
}

.teams-big-arrows {
  border-top: 1px dashed #fff;
  border-left: 1px dashed #fff;
  border-right: 1px dashed #fff;
  border-radius: 5px 5px 0 0;
  width: 50%;
  height: 25px;
  position: relative;
}

.teams-user-circle {
  background: #fff;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.teams-structure {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: center;

  :nth-child(odd) {
    div {
      left: 0;
    }
    span {
      left: -4px;
    }
  }

  :nth-child(even) {
    div {
      right: 0;
    }
    span {
      right: -4px;
    }
  }
}
