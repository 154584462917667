.userLine {
  opacity: 0.3;
  border-left: 1px dashed #fff;
  height: 20px;
}

.userCircle {
  opacity: 0.3;
  background: #fff;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.horizontal-border {
  border-top: 1.5px solid #fff;
  border-left: 1.5px solid #fff;
  border-right: 1.5px solid #fff;
  height: 10px;
  border-radius: 5px 5px 0 0;
}
.directs-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.direct-container {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  width: 50%;
  justify-content: flex-start;
}

.direct-container-end {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  width: 50%;
  justify-content: flex-end;
}

.direct-left {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: center;

  div {
    left: 0;
  }
  span {
    left: -4px;
  }
}

.direct-right {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: center;

  div {
    right: 0;
  }
  span {
    right: -4px;
  }
}

.dashed-line {
  position: absolute;
  width: 40%;
  top: 27px;
  border-top: 1px dashed #fff;
}

.circle {
  position: absolute;
  background: #fff;
  width: 9px;
  height: 9px;
  top: 23px;
  border-radius: 50%;
}
